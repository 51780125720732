<template>
  <div>
    <div class="row">
      <div class="col-md-4 profile-aside-menu">
        <EmployeeProfileAsideMenu />
      </div>

      <div class="col-md-8">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeProfileAsideMenu from "./components/EmployeeProfileAsideMenu";

export default {
  name: "EmployeeProfile",

  components: {
    EmployeeProfileAsideMenu,
  },

  mounted() {
    this.$setMaxContentWidth(900);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .profile-aside-menu {
    display: none;
  }
}
</style>
